import * as React from "react"
import type { HeadFC } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import PageHead from "../components/page-head"
import Contact from "../components/contact"
import SectionHeadline from "../components/section-headline"
import { FadeInComponent, ZoomInComponent } from "../components/animate"

import "../styles/globals.scss"
import "../styles/hederadx.scss"

const HederaDxPage = () => {
  return (
    <Layout className="hederadx">
      <div className="section hero">
        <h2>
          Better therapy options for cancer patients
        </h2>
        <p>
          <a href="https://www.hederadx.com/" target={"_blank"}>with Hedera Dx</a>
        </p>
        <StaticImage
          src="../images/hederadx-project.png"
          loading="eager"
          alt="Hedera DX project illustration"
          className="centered-image" />
      </div>
      

      <div className="section domain">
        <div className="flex">
          <div className="flex-cell right-padded">
            <div>
              <StaticImage
                src="../images/hederadx-logo.png"
                loading="eager"
                alt="Hedera"
                className="hedera-logo"
                objectFit="contain"
                objectPosition="0 0"
              />
            </div>
          </div>
          <div className="flex-cell">
            <p>
              Hedera Dx is a techbio company on a mission to transform the detection, diagnostics, and care of cancer.
            </p>            
            <p>
              To build their digital capabilities in an environment of high uncertainty, heavy regulatory environment and fast-changing requirements, Hedera Dx needed to build a multifunctional product team of experienced quick learners. Align was brought on board to extend Hedera Dx's commitment to excellence to their most important customer-facing medium, their digital platform.
            </p>
          </div>
        </div>
        <div className="flex top-padded">
          <div className="flex-cell">
            <h3>At a glance</h3>
          </div>
          <div className="flex-cell">
            <ul>
              <li>A pioneering innovation: building a less invasive and significantly cheaper way to provide care for cancer patients</li>
              <li>Record efficiency within a regulation-laden industry: we took the digital product MVP from research to market in 10 months</li>
              <li>World-class usability and an end-to-end workflow</li>
              <li>Applications for a cancerless future: Hedera Dx's solution has potential for both cancer screening and more efficient therapy options</li>
            </ul>
          </div>
        </div>
        <div className="flex top-padded">
          <div className="flex-cell">
            <SectionHeadline text="The Challenge" />
          </div>
          <div className="flex-cell">
            <h3>
              <FadeInComponent>
                Detecting cancer biomarkers is invasive, expensive, and difficult
              </FadeInComponent>
            </h3>
          </div>
        </div>
        <div className="flex top-padded">
          <div className="flex-cell right-padded">
            <StaticImage
              src="../images/dna.png"
              loading="eager"
              alt="Dna"
            />
          </div>
          <div className="flex-cell">
            <p>
              While modern medicine has done wonders to the eradication of a multitude of deadly diseases, cancer remains one of the leading causes of death worldwide. Not only are cancer cells a moving target to therapies; the disease is also expensive, invasive, and often difficult to diagnose with the traditional tissue biopsy method.
            </p>
            <p>
              Hedera Dx brings to the market an alternate method for molecular profiling of tumors. Cancerous cells release DNA to the blood, and that DNA can be sampled and analysed from the blood. This method is called liquid biopsy. Liquid biopsies show promise in both the early detection and relapse monitoring of cancer.
            </p>
            <p>
              While liquid biopsies have been gaining popularity in the US, the decentralised laboratory network and strict medical device regulation have previously kept innovations of the field from taking root in Europe.
            </p>
          </div>
        </div>
      </div>
      
      <div className="section solution">
        <div className="flex">
          <div className="flex-cell">
            <SectionHeadline text="The Solution" />
          </div>
          <div className="flex-cell">
            <h3>
              <FadeInComponent> 
                A tool that helps doctors help patients when it matters the most
              </FadeInComponent>
            </h3>
            <p>
              Hedera Dx had two main goals: one, to drastically reduce cancer mortality; and two, to help the European decentralised medical laboratory field unite in the face of the world's deadliest disease.  
            </p>
            <p>
              Align joined the mission in September 2021, becoming the core of Hedera Dx's interim product development team. 
            </p>
            <p>
              From drawing a sample to reporting on lab results, we discovered that the biggest systemic pain points of cancer care professionals derived from lack of user-centric products in the field.
            </p>
            <p>
              This is how we structured the product.
            </p>
          </div>
        </div>

        <div className="workflow">
          <ZoomInComponent delay={100}>
            <div>
              <StaticImage
                src="../images/hederadx-illustration-samples.png"
                loading="eager"
                alt="Hedera"
              />
              <p>The liquid biopsy test is ordered through the Hedera Dx platform. A blood sample is drawn from the patient.</p>
            </div>
          </ZoomInComponent>
          <ZoomInComponent delay={200}>
            <div>
              <StaticImage
                src="../images/hederadx-illustration-sequencing.png"
                loading="eager"
                alt="Hedera"
              />
              <p>The sample goes through laboratory preparation, DNA sequencing and bioinformatics analysis. The result is a list of found genomic variants.</p>
            </div>
          </ZoomInComponent>
          <ZoomInComponent delay={300}>
            <div>
              <StaticImage
                src="../images/hederadx-illustration-lab.png"
                loading="eager"
                alt="Hedera"
              />            
              <p>The lab reviews the findings in the Hedera Dx platform and composes a clinical test report. To facilitate this, the platform includes detailed quality metrics, access to online genomic databases, a list of ineffective and effective therapy options and a powerful report builder &mdash; all integrated to a super simple UI. </p>
            </div>
          </ZoomInComponent>
          <ZoomInComponent delay={400}>
            <div>
              <StaticImage
                src="../images/hederadx-illustration-report.png"
                loading="eager"
                alt="Hedera"
              />
              <p>The clinical test report is delivered securely and promptly to the clinician via the Hedera Dx platform. The report includes an organised, glanceable view to the results, possible therapies, and other findings.  </p>
            </div>
          </ZoomInComponent>
        </div>
      </div>

      <div className="section quote">
        <StaticImage
          src="../images/quote.svg"
          loading="eager"
          alt="Quote"
          objectFit="contain"
          className="quote-icon"
        />
        <div>
          <p>
            The Align team hit the ground running, made a significant effort to acquire an impressive amount of domain knowledge of our highly complex industry, and identified our most important challenges to solve very quickly.
          </p>
          <p>
            Not only did they do a great job in building the Hedera Dx platform &mdash; they also set us up for success by self-organising around our biggest digital hurdles, constructing and executing on the product roadmap, helping us build our own tech team, and demoing the platform to key stakeholders.
          </p>
          <p className="quoter">
            <span className="name">Tommi Lehtonen</span>
            CEO, Hedera Dx
          </p>
        </div>
      </div>

      <div className="section timeline">
        <StaticImage
          src="../images/dna-strings.png"
          loading="eager"
          alt="Hedera"
          className="centered-image"
        />        
        <div className="flex">
          <div className="flex-cell">
            <SectionHeadline text="Project timeline" />
          </div>
        </div>
        <div className="flex">
          <div className="flex-cell right-padded">
            <h3>From research to market in 10 months</h3>
          </div>
          <div className="flex-cell">
            <div className="timeline-step">
              <StaticImage
                src="../images/timeline-1.png"
                loading="eager"
                alt="Hedera"
                className="timeline-step-number"
                width={100}
              />
              <FadeInComponent>
                <h4>
                  Discovery: 3 months
                </h4>
                <p>
                  To gain a thorough understanding of the domain and medical professionals' needs, our work started with travelling to four major European laboratories and university hospitals to interview laboratorists, pathologists, and oncologists. 
                </p>
                <ul className="emphasized">
                  <li>Exploration of hypothetical techno-judicial setups </li>
                  <li>Establishing lean ways of working and a secure internal infrastructure</li>
                </ul>
              </FadeInComponent>
            </div>
            <div className="timeline-step">
              <StaticImage
                src="../images/timeline-2.png"
                loading="eager"
                alt="Hedera"
                className="timeline-step-number"
                width={100}
              />
              <FadeInComponent delay={100}>
                <h4>
                  Prototyping: 1,5 months
                </h4>
                <p>
                  The most extensive task in taking our concept to a functioning prototype was making all the necessary integrations to existing data sources.  
                </p>
                <ul className="emphasized">
                  <li>Extensive feasibility study to data providers </li>
                  <li>Regulatory hygiene tasks to ensure smooth quality management and design controls maintenance</li>
                  <li>Ongoing iteration of the user interface </li>
                </ul>
              </FadeInComponent>
            </div>
            <div className="timeline-step">
              <StaticImage
                src="../images/timeline-3.png"
                loading="eager"
                alt="Hedera"
                className="timeline-step-number"
                width={100}
              />
              <FadeInComponent delay={200}>
                <h4>
                  Go-to-market: 6 months
                </h4>
                <p>
                  When the most important features of the platform had been nailed down, we started executing on the roadmap. 
                </p>
                <ul className="emphasized">
                  <li>Two developers added to the team to hit a strict deadline</li>
                  <li>Constant monitoring and solving inevitable regulatory hurdles</li>
                </ul>
              </FadeInComponent>
            </div>
          </div>
        </div>     
      </div>

      <div className="section footer">
        <div>
          <h3>Sculpting realities</h3>
          <p>Trying to solve a complex digital challenge?<br />
            We are here to help. </p>
        </div>
        <Contact theme={'dark'} />
      </div>
    </Layout>
  )
}

export default HederaDxPage

export const Head: HeadFC = () => <PageHead />
